"use strict";
(function (module) {
    var ImportController = function ($q, $window, $location, $scope, $filter, portingService, folderService, sourceDataService, env, API_ROUTES, qs, triggerOverrides, OVERRIDE_EVENTS) {
        var translate = $filter('translate');
        var $ctrl = this;
        $scope.$ctrl = $ctrl;
        $ctrl.$createReportCtrl = $scope.$parent.$parent.$ctrl;
        var currActiveSurveyId = env.get('activeSurveyId');
        var sources;
        var STATUS_CODES = {
            PENDING: 'pending',
            SUCCEEDED: 'succeeded',
            FAILED: 'failed',
        };
        $ctrl.singleFieldsetId = triggerOverrides({
            event: OVERRIDE_EVENTS.SINGLE_FIELDSET,
            sync: true
        });
        var configFlags = triggerOverrides({
            event: OVERRIDE_EVENTS.CONFIG_FLAGS,
            sync: true,
            data: {}
        });
        var widgetCountLimit = configFlags.widgetCountLimit;
        // methods called from prCreateReportController
        angular.extend($ctrl.$createReportCtrl, {
            importContainer: function importContainer() {
                if ($ctrl.errorMessage) {
                    // sanity check
                    return;
                }
                var sourceMap = {};
                _.forEach($ctrl.sourceMapArray, function (entry) {
                    sourceMap[entry.from] = entry.to;
                });
                setStatus(STATUS_CODES.PENDING);
                return $q.resolve().then(function () {
                    return portingService.importReport($ctrl.containerDef, sourceMap);
                }).then(function (response) {
                    setStatus(STATUS_CODES.SUCCEEDED);
                    var newContainerId = response.container._id;
                    $ctrl.$createReportCtrl.creating = true;
                    $window.location = qs.append(API_ROUTES.VIEW + currActiveSurveyId + '/' + newContainerId, $location.search());
                }).catch(function (error) {
                    $ctrl.$createReportCtrl.creating = false;
                    console.error(error);
                    setStatus(STATUS_CODES.FAILED);
                });
            },
            isImportReady: function isImportReady() {
                if ($ctrl.containerDef) {
                    // at least some of the sources must point to the current active survey
                    // in order for the new report to show up in the list of accessible reports
                    var newSources = _.map($ctrl.sourceMapArray, 'to');
                    var includesActiveSurvey = _.includes(newSources, currActiveSurveyId);
                    var surveyName = $ctrl.getSourceLabel(currActiveSurveyId);
                    $ctrl.errorMessage =
                        !$ctrl.singleFieldsetId &&
                            !includesActiveSurvey &&
                            translate('PR.ACTIVE_SURVEY_REQUIRED', {
                                activeSurveyName: surveyName,
                                activeSourceId: currActiveSurveyId
                            });
                }
                return !$ctrl.errorMessage &&
                    _.get($ctrl, 'containerDef.description') &&
                    $ctrl.statusCode !== STATUS_CODES.PENDING &&
                    $ctrl.statusCode !== STATUS_CODES.SUCCEEDED &&
                    _.every($ctrl.sourceMapArray, _.property('to'));
            }
        });
        // methods called from the view
        angular.extend($ctrl, {
            extension: portingService.FILE_EXTENSION,
            uploadContainer: function uploadContainer(event) {
                $ctrl.errorMessage = null;
                $ctrl.containerDef = null;
                var input = (event.target.files || [])[0];
                if (!isValidUpload(input)) {
                    return;
                }
                var fileReader = new FileReader();
                fileReader.onload = function (file) {
                    if (!isValidContainer(file)) {
                        return;
                    }
                    $ctrl.containerDef = JSON.parse(file.target.result);
                    initSourceMap();
                };
                fileReader.readAsText(input);
            },
            getSourceOptions: function getSourceOptions(selectedSource) {
                return getUserSources().then(function (userSources) {
                    return folderService.getFolderMenu(currActiveSurveyId, _.keys(userSources)).then(function (folderMenu) {
                        return setSelected(selectedSource, folderMenu);
                    });
                });
            },
            getSourceLabel: function getSourceLabel(sourceId, fallback) {
                if (!sources) {
                    getUserSources();
                }
                return _.get(sources, [sourceId, 'name'], fallback) || translate('SELECT_SOURCE');
            },
            setSource: function setSource($index, sourceId) {
                $ctrl.sourceMapArray[$index].to = sourceId;
            }
        });
        function getUserSources() {
            if (!sources) {
                return sourceDataService.getUserSources().then(function (userSources) {
                    sources = userSources;
                    return sources;
                });
            }
            return $q.when(sources);
        }
        function initSourceMap() {
            return getUserSources().then(function (userSources) {
                // By default, map the active survey at the time of export
                // to the active survey at the time of import
                var origActiveSurveyId = $ctrl.containerDef.contextMeta.ReportImport.exportContext.activeSurveyId;
                $ctrl.sourceMapArray = [{
                        from: origActiveSurveyId,
                        to: currActiveSurveyId
                    }];
                var originalSources = $ctrl.containerDef.contextMeta.surveyId;
                _.forEach(originalSources, function (sourceId) {
                    if (sourceId !== origActiveSurveyId) {
                        // By default, map sources to themselves if user has permissions
                        var toSourceId = $ctrl.singleFieldsetId;
                        if (!toSourceId) {
                            toSourceId = userSources[sourceId] ? sourceId : null;
                        }
                        $ctrl.sourceMapArray.push({
                            from: sourceId,
                            to: toSourceId
                        });
                    }
                });
            });
        }
        function setSelected(selectedSource, menuItems) {
            return menuItems.map(function (menuItem) {
                if (menuItem.value) {
                    if (menuItem.value === selectedSource) {
                        menuItem.selected = true;
                    }
                }
                else if (_.get(menuItem, 'submenu.items')) {
                    menuItem.submenu.items = setSelected(selectedSource, menuItem.submenu.items);
                }
                return menuItem;
            });
        }
        function isValidUpload(file) {
            try {
                if (!file) {
                    $ctrl.errorMessage = translate('PR.NO_FILE_SELECTED');
                }
                else if (!_.endsWith(file.name, portingService.FILE_EXTENSION)) {
                    $ctrl.errorMessage = translate('PR.INVALID_FILE_TYPE', {
                        validFileTypes: portingService.FILE_EXTENSION
                    });
                }
                return !$ctrl.errorMessage;
            }
            catch (error) {
                // must have been an error at file.name
                $ctrl.errorMessage = translate('PR.INVALID_FILE_TYPE', {
                    validFileTypes: portingService.FILE_EXTENSION
                });
                return !$ctrl.errorMessage;
            }
            finally {
                $scope.$digest();
            }
        }
        function isValidContainer(file) {
            try {
                var containerDef = JSON.parse(file.target.result);
                if (!_.get(containerDef, 'contextMeta.ReportImport.exportContext.activeSurveyId')) {
                    $ctrl.errorMessage = translate('PR.UNRECOGNIZED_CONTAINER');
                    return false;
                }
                var widgetCount = _.sumBy(containerDef.pages, 'widgets.length');
                if (widgetCount > widgetCountLimit) {
                    $ctrl.errorMessage = translate('PR.WIDGET_COUNT_EXCEED_LIMIT_INFO', { widgetCountLimit: widgetCountLimit });
                    return false;
                }
                var override = triggerOverrides({
                    event: OVERRIDE_EVENTS.VALIDATE_IMPORTED_CONTAINER_DEF,
                    sync: true,
                    data: containerDef
                });
                if (override === null || override === void 0 ? void 0 : override.errorMessage) {
                    $ctrl.errorMessage = override.errorMessage;
                    return false;
                }
                return true;
            }
            catch (error) {
                console.error(error.message);
                // must have been an error in JSON.parse
                $ctrl.errorMessage = translate('PR.INVALID_FILE');
                return false;
            }
            finally {
                $scope.$digest();
            }
        }
        function setStatus(statusCode) {
            $ctrl.statusCode = statusCode;
            if (statusCode === STATUS_CODES.PENDING) {
                $ctrl.statusMessage = translate('PR.IMPORT_PENDING');
            }
            else if (statusCode === STATUS_CODES.SUCCEEDED) {
                $ctrl.statusMessage = translate('PR.IMPORT_SUCCESSFUL');
            }
            else {
                $ctrl.statusMessage = translate('PR.IMPORT_FAILED');
            }
        }
    };
    module.controller('prImportController', [
        '$q',
        '$window',
        '$location',
        '$scope',
        '$filter',
        'pr.portingService',
        'pr.folderService',
        'prSourceDataService',
        'rsEnvironment',
        'pr.API_ROUTES',
        'prQueryString',
        'prOverrideTrigger',
        'pr.OVERRIDE_EVENTS',
        ImportController
    ]);
    module.directive('importOnChange', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var onChangeHandler = scope.$eval(attrs.importOnChange);
                element.on('change', onChangeHandler);
                element.on('$destroy', function () {
                    element.off();
                });
            }
        };
    });
})(angular.module('qualtrics.pr'));
