"use strict";
(function (app) {
    'use strict';
    var STRIP_HTML_REGEX = /<[^>]+>/gm;
    app.filter('stripHTML', function () {
        return function (text) {
            return String(text || '').replace(STRIP_HTML_REGEX, '');
        };
    });
    var Sanitize = function ($filter) {
        this.$filter = $filter;
    };
    /**
     * Strips HTML from a string using DOMParser.
     * Also decodes encoded HTML if any is present.
     *
     * @param {string}
     *
     * @returns {string} that has been stripped and decoded of HTML
     */
    Sanitize.prototype.stripHTML = function (string) {
        var parser = new DOMParser();
        var doc = parser.parseFromString(string, 'text/html');
        var decodedText = doc.body.textContent || '';
        return this.$filter('stripHTML')(decodedText);
    };
    Sanitize.$inject = ['$filter'];
    app.service('prSanitize', Sanitize);
})(angular.module('qualtrics.pr'));
